<template>
  <div>
    <a-modal :visible.sync="show"
             width="700px"
             title="审核退款"
             :footer="null"
             @cancel="$emit('update:show',false)">

      <div class="footer" style="margin-top:0;">
        <a-form-model :model="formData"
                      :label-col="labelCol"
                      :wrapper-col="wrapperCol"
                      ref="refundDetailRef">
          <!-- 顾客提交的银行信息 -->
          <a-form-model-item :labelCol="{ span: 4 }" prop label="顾客收款信息"
            v-if="infoData.refund_account_name || infoData.refund_bank || infoData.refund_card_number || infoData.refund_phone">
            <div>
              <div v-if="infoData.refund_account_name">户名：{{infoData.refund_account_name}}</div>
              <div v-if="infoData.refund_bank">银行：{{infoData.refund_bank}}</div>
              <div v-if="infoData.refund_card_number">卡号：{{infoData.refund_card_number}}</div>
              <div v-if="infoData.refund_phone">手机号：{{infoData.refund_phone}}</div>
            </div>
          </a-form-model-item>
          <!-- 可退金额 -->
          <template v-if="infoData.audit_status == 1">
            <a-form-model-item :labelCol="{ span: 4 }" prop label="可退金额">
              <span class="font-bold">{{infoData.return_amount}}元</span>
              <span class="grey">
              （总余额 {{infoData.balance}}元
                <template v-if="infoData.give > 0">，其中赠送金额{{infoData.give}}元</template>
                ）
              </span>
            </a-form-model-item>
          </template>
          <!-- 退款金额 -->
          <a-row v-if="infoData.audit_status == 2 || infoData.audit_status == 5 || infoData.audit_status == 6">
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 4 }" prop label="退款金额">
                <span class="font-bold">{{infoData.return_amount}}元</span>
                <!-- <span> （对应面额 {{infoData.apply_amount}}元）</span> -->
              </a-form-model-item>
            </a-col>
          </a-row>

          <!-- 审核备注 -->
          <a-row>
            <a-col :span="24">
              <a-form-model-item :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }" prop label="审核备注">
                <a-input v-if="infoData.audit_status == 1" v-model="formData.audit_remarks"></a-input>
                <span v-else>{{infoData.audit_remarks}}</span>
              </a-form-model-item>
            </a-col>
          </a-row>
          
          <!-- 退款流水 -->
          <div v-if="infoData.return_flow && infoData.return_flow.length" class="mb-3">
            <base-table
                  rowKey="flow_no"
                  :customHeight="300"
                  :columnsData="flowColumns"
                  :tableData="infoData.return_flow">
            </base-table>
          </div>

        </a-form-model>
      </div>

      <!-- 底部按钮 -->
      <div>
        <div v-if="infoData.audit_status == 1" 
            class="flex items-center justify-center pt-3"
            style="border-top:1px solid lightgray;">
          <a-button @click="handlerCancel" type="info" class="mr-7">拒绝退款</a-button>
          <a-button @click="handlerSave" type="primary">审核通过</a-button>
        </div>
      </div>
    </a-modal>

    <a-modal
      class="preview-class"
      title="退款图片"
      width="50%"
      :visible="previewVisible"
      :footer="null"
      @cancel="previewVisible = false"
    >
      <img style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import { 
  getWalletRefundDetail, 
  getWalletRefundAmount,
  // updateWalletRefund,
  auditWalletRefund
} from "@/api/refund.js"

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String, Number],
    },
  },
  data () {
    return {
      form: {},
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      previewVisible: false,
      previewImage: "",
      flowColumns: [
        {
          title: "退款流水号",
          dataIndex: "flow_no",
          align: "center",
        },
        {
          title: "申请金额",
          dataIndex: "apply_amount",
          align: "center",
          // width: "80px",
          // slots: {
          //   customRender: "type",
          // },
          // slotsType: "format",
          // slotsFunc: (val) => formatGoodsType(val),
        },
        {
          title: "退还金额",
          dataIndex: "return_amount",
          align: "center",
        },
        {
          title: "财务账户",
          dataIndex: "financial_account_name",
          align: "center",
        },
        {
          title: "退款状态",
          dataIndex: "return_status",
          align: "center",
          // width: "80px",
          slots: {
            customRender: "return_status",
          },
          slotsType: "format",
          slotsFunc: (val) => {
            return val == "2" ? "已退款" : "待退款"
          },
        },
        {
          title: "退款时间",
          dataIndex: "return_time",
          align: "center",
          // width: "80px",
        }
      ],
      infoData: {},
      formData: {
        id: this.id,
        // audit_status: 2,
        audit_remarks: "",
      },
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    async initData() {
      getWalletRefundDetail({ id: this.id }).then((res) => {
        if (res.code === 0) {
          this.infoData = res.data
          let audit_amount = 0

          this.infoData.audit_refund_amount = audit_amount
          this.formData.audit_remarks = this.infoData.audit_remarks
          if(res.data.audit_status == 1){
            this.initWalletRefundAmount()
          }
        }
      })
    },

    initWalletRefundAmount(){
      getWalletRefundAmount({ id: this.id }).then((res) => {
        if (res.code === 0) {
          const data = Object.assign(this.infoData, res.data)
                data.give = Math.round((Number(data.balance)-Number(data.return_amount)) * 100) / 100
          this.infoData = data
          // this.infoData.max_refund_balance = res.data.balance
          // this.infoData.max_refund_amount = res.data.return_amount
        }
      })
    },
    // 审核：通过
    handlerSave () {
      const params = Object.assign({
        audit_status: 2,
        return_amount: this.infoData.return_amount,
      }, this.formData)

      auditWalletRefund(params).then(res => {
        if (res.code === 0) {
          this.$message.success('审核通过. 请让财务打款并上传凭证')
          this.initData()
          this.$parent.initData()
          // this.$emit('update:show', false)
        }
      })
    },
    // 审核：拒绝
    handlerCancel () {
      this.$confirm({
        title: "温馨提示",
        content: '拒绝用户的退款申请?',
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          const params = Object.assign({
            audit_status: 3
          }, this.formData)
          auditWalletRefund(params).then(res => {
            if (res.code === 0) {
              this.$message.success('已拒绝')
              this.$parent.initData()
              this.$emit('update:show', false)
            }
          })
        },
      })
    },
    
    handlerBigImg (e) {
      this.previewImage = e.image_url
      this.previewVisible = true
    },
    // handlerEdit () {
    //   const params = {
    //     id: this.formData.id,
    //     audit_remarks: this.formData.audit_remarks,
    //   }
    //   updateRefund(params).then(res => {
    //     if (res.code === 0) {
    //       this.$message.success('修改成功！')
    //       this.$parent.initData()
    //       this.$emit('update:show', false)
    //     }
    //   })
    // },
  },
}
</script>

<style lang="less" scoped>
.audit_amount_input{
  max-width:140px !important;
  .ant-input-number{
    width: 100px;
    max-width:120px;
  }
  .plus{
    margin: 0 5px;
  }
}
.footer {
  margin: 20px 0;
  .img-class {
    width: 100px;
    height: 75px;
    // object-fit: cover;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  .grey{
    color: #999;
  }
}

.ant-form-item {
  margin-bottom: 0;
}
</style>