<template>
  <div>
    <a-modal
      :visible.sync="show"
      width="600px"
      title="钱包充值详情"
      :footer="null"
      @cancel="$emit('update:show', false)"
    >
      <a-form-model v-if="rechargeDetailData"
        layout="horizontal"
        :label-col="labelCol"
        :wrapper-col="wrapperCol">
          <a-row>
            <a-form-model-item label="充值订单号">
              {{rechargeDetailData.recharge_order_no}}
            </a-form-model-item>
          </a-row>
          <a-row>
            <a-form-model-item label="充值时间">
              {{rechargeDetailData.recharge_time}}
            </a-form-model-item>
          </a-row>
          <a-row>
            <a-form-model-item label="财务账户">
              {{rechargeDetailData.recharge_financial_account_name}}
            </a-form-model-item>
          </a-row>
          <a-row>
            <a-form-model-item label="微信子商户号">
              {{rechargeDetailData.recharge_weixin_merchant_id}}
            </a-form-model-item>
          </a-row>
          <a-row>
            <a-form-model-item label="充值流水号">
              {{rechargeDetailData.recharge_pay_serial_no}}
            </a-form-model-item>
          </a-row>
          <a-row>
            <a-form-model-item label="充值本金">
              ￥{{rechargeDetailData.recharge_amount}}
            </a-form-model-item>
          </a-row>
          <a-row>
            <a-form-model-item label="充值面额">
              ￥{{rechargeDetailData.recharge_denomination}}
            </a-form-model-item>
          </a-row>
          <a-row>
            <a-form-model-item label="剩余本金">
              ￥{{rechargeDetailData.remain_amount}}
            </a-form-model-item>
          </a-row>
          <a-row>
            <a-form-model-item label="剩余面额">
              ￥{{rechargeDetailData.remain_denomination}}
            </a-form-model-item>
          </a-row>
          <a-row>
            <a-form-model-item label="充值发票">
              <a-button type="link" class="p-0" 
                v-if="rechargeDetailData.invoice_apply_id" 
                @click="handlerInvoiceDetail(rechargeDetailData.invoice_apply_id)">￥{{rechargeDetailData.invoice_open_amount}}</a-button>
              <template v-else>未开票</template>
            </a-form-model-item>
          </a-row>
          
        </a-form-model>

    </a-modal>

    <!-- 发票详情 -->
    <InvoiceDetail
      v-if="isShowInvoiceDetail"
      :show.sync="isShowInvoiceDetail"
      :requestID="transformDetailID"
    />

  </div>
</template>

<script>
import { getVipRechargeDetail } from '@/api/vip.js'
import InvoiceDetail from "../../invoice/topup-invoice/components/detail"

export default {
  components: { InvoiceDetail },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    rechargeId: {
      type: [String, Number],
      default: null,
    },
    rechargeInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },

      rechargeDetailData: null,

      transformDetailID: '',
      isShowInvoiceDetail: false,
    }
  },
  mounted() {
    if(this.rechargeId){
      this.getRechargeDetail(this.rechargeId)
    }else if(this.rechargeInfo){
      this.rechargeDetailData = this.rechargeInfo
    }
  },
  methods: {
    // handlerMember(){
    //   const newPage = this.$router.resolve({
    //     name: "memberDetail",
    //     query: { 
    //       member_id: this.info.user_id
    //     },
    //   });
    //   window.open(newPage.href, "_blank");
    // },

    getRechargeDetail(recharge_id){
      getVipRechargeDetail({
        recharge_id: recharge_id
      }).then(res=>{
        if(res.code == 0){
          this.rechargeDetailData = res.data
        }
      })
    },

    handlerInvoiceDetail(id){
      this.transformDetailID = id
      this.isShowInvoiceDetail = true
    },

  },
}
</script>

<style lang="less" scoped>
.preview-class /deep/ .ant-modal-body {
  padding: 10px;
}

.header {
  display: flex;
  margin-bottom: 10px;
  h4,span{
    margin-right:30px;
  }
}

.footer {
  margin-top: 20px;
  .img-class {
    width: 100px;
    height: 75px;
    object-fit: cover;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
}

.ant-form-item {
  margin-bottom: 0;
}
</style>
